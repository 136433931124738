import React, { useState } from "react";
import "./ThreeStateToggleButton.css";

const ThreeStateToggleButton = ({ initialState, onChange }) => {
  const [status, setStatus] = useState(initialState || "pending");

  const handleClick = (newStatus) => {
    // Only allow switching to Cancelled or Confirmed
    if (newStatus !== "pending") {
      setStatus(newStatus);
      onChange(newStatus); // Notify parent about the state change
    }
  };

  return (
    <div className="three-state-toggle">
      <div className="button-group">
        {/* Cancelled button */}
        <button
          className={`toggle-button ${
            status === "cancelled" ? "selected-cancelled" : ""
          }`}
          onClick={() => handleClick("cancelled")}
        >
          Cancelled
        </button>

        {/* Pending button (read-only/disabled) */}
        <button
          className={`toggle-button ${
            status === "pending" ? "selected-pending" : ""
          }`}
          disabled
        >
          Pending
        </button>

        {/* Confirmed button */}
        <button
          className={`toggle-button ${
            status === "confirmed" ? "selected-confirmed" : ""
          }`}
          onClick={() => handleClick("confirmed")}
        >
          Confirmed
        </button>
      </div>
    </div>
  );
};

export default ThreeStateToggleButton;
