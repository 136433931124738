import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Button,
  Modal,
  Input,
  Label,
} from "reactstrap";
import ReactQuill from "react-quill";

const FormComponent = ({ addData, currentPolicyData, closeModal }) => {
    // State for Cancellation Policy Text

    console.log("policy-1",currentPolicyData);

    const [cancelPolicyText, setCancelPolicyText] = useState("");
  
    // State for Cancellation Policy Rules
    const [cancelPolicyRules, setCancelPolicyRules] = useState([
      { startDays: "", endDays: "", type: "percentage", amount: "" },
    ]);
  
    // State for tracking validation errors
    const [errors, setErrors] = useState({});
  
    /**
     * useEffect to initialize form state based on currentPackageData
     * This runs on component mount and whenever currentPackageData changes
     */
    useEffect(() => {
      if (currentPolicyData) {
        const { text, rules } = currentPolicyData;
  
        setCancelPolicyText(text || "");
  
        if (Array.isArray(rules) && rules.length > 0) {
          setCancelPolicyRules(
            rules.map((rule) => ({
              startDays: rule.startDays || "",
              endDays: rule.endDays || "",
              type: rule.type || "percentage",
              amount: rule.amount || "",
            }))
          );
        } else {
          setCancelPolicyRules([
            { startDays: "", endDays: "", type: "percentage", amount: "" },
          ]);
        }
      }
    }, [currentPolicyData]);
  
    /**
     * Handler to update Cancellation Policy Text
     */
    const handleCancelPolicyTextChange = (value) => {
      setCancelPolicyText(value);
    };
  
    /**
     * Handlers for Cancellation Policy Rules
     */
    const handleRuleChange = (index, field, value) => {
      const updatedRules = cancelPolicyRules.map((rule, i) =>
        i === index ? { ...rule, [field]: value } : rule
      );
      setCancelPolicyRules(updatedRules);
    };
  
    const addRule = () => {
      setCancelPolicyRules([
        ...cancelPolicyRules,
        { startDays: "", endDays: "", type: "percentage", amount: "" },
      ]);
    };
  
    const removeRule = (index) => {
      const updatedRules = cancelPolicyRules.filter((_, i) => i !== index);
      setCancelPolicyRules(updatedRules);
    };
  
    /**
     * Validation Function
     */
    const validateForm = () => {
      let valid = true;
      let newErrors = {};
  
      // Validate Cancellation Policy Text
      const plainText = new DOMParser()
        .parseFromString(cancelPolicyText, "text/html")
        .body.textContent || "";
      if (!plainText.trim()) {
        newErrors.cancelPolicyText = "Cancellation policy text is required.";
        valid = false;
      }
  
      // Validate Cancellation Policy Rules
      if (cancelPolicyRules.length === 0) {
        newErrors.cancelPolicyRules =
          "At least one cancellation policy rule is required.";
        valid = false;
      } else {
        cancelPolicyRules.forEach((rule, index) => {
          const { startDays, endDays, type, amount } = rule;
          if (
            startDays === "" ||
            endDays === "" ||
            type === "" ||
            amount === ""
          ) {
            newErrors[`rule_${index}`] = "All fields in the rule are required.";
            valid = false;
          } else {
            if (isNaN(startDays) || isNaN(endDays)) {
              newErrors[`rule_${index}`] =
                "Start Days and End Days must be numbers.";
              valid = false;
            } else {
              if (Number(startDays) < Number(endDays)) {
                newErrors[`rule_${index}`] =
                  "Start Days must be greater than or equal to End Days.";
                valid = false;
              }
              if (Number(startDays) < 0 || Number(endDays) < 0) {
                newErrors[`rule_${index}`] =
                  "Start Days and End Days cannot be negative.";
                valid = false;
              }
            }
  
            const allowedTypes = ["percentage", "fixed_deduction"];
            if (!allowedTypes.includes(type)) {
              newErrors[`rule_${index}`] =
                "Type must be either 'percentage' or 'fixed_deduction'.";
              valid = false;
            }
  
            if (isNaN(amount) || Number(amount) < 0) {
              newErrors[`rule_${index}`] =
                "Amount must be a non-negative number.";
              valid = false;
            }
          }
        });
  
        // Check for Overlapping Ranges
        const sortedRules = [...cancelPolicyRules].sort(
          (a, b) => Number(b.startDays) - Number(a.startDays)
        );
  
        for (let i = 0; i < sortedRules.length - 1; i++) {
          const currentRule = sortedRules[i];
          const nextRule = sortedRules[i + 1];
  
          if (Number(currentRule.endDays) < Number(nextRule.startDays)) {
            newErrors[`rule_${i}`] = `Rule ${
              i + 1
            } overlaps with Rule ${i + 2}.`;
            valid = false;
          }
        }
      }
  
      setErrors(newErrors);
      return valid;
    };
  
    /**
     * Handler for Form Submission
     */
    const handleSubmit = (e) => {
      e.preventDefault();
  
      if (validateForm()) {
        // Prepare the cancellation policy data
        const cancellationPolicy = {
          text: cancelPolicyText.trim(),
          rules: cancelPolicyRules.map((rule) => ({
            startDays: Number(rule.startDays),
            endDays: Number(rule.endDays),
            type: rule.type,
            amount: Number(rule.amount),
          })),
        };
  
        // Submit the data
        addData(cancellationPolicy);

        // close the modal

        closeModal();
  
        // Optionally, reset the form fields if adding a new policy
        if (!currentPolicyData) {
          setCancelPolicyText("");
          setCancelPolicyRules([
            { startDays: "", endDays: "", type: "percentage", amount: "" },
          ]);
        }
  
        // Clear errors
        setErrors({});
      } else {
        // Optionally, display a general error message
        // e.g., using a toast notification library like react-toastify
        // Toast.error("Please fix the errors in the form.");
      }
    };
  
    return (
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <Form onSubmit={handleSubmit}>
                {/* Cancellation Policy Text */}
                <div className="mb-4">
                  <Label for="cancelPolicyText">Cancellation Policy</Label>
                  <ReactQuill
                    style={{
                      height: "200px",
                      width: "100%",
                      marginBottom: "20px",
                    }}
                    value={cancelPolicyText}
                    onChange={handleCancelPolicyTextChange}
                    modules={{
                      toolbar: [
                        [{ header: [1, 2, 3, false] }],
                        [
                          "bold",
                          "italic",
                          "underline",
                          "strike",
                          "blockquote",
                        ],
                        [{ list: "ordered" }, { list: "bullet" }],
                        ["link", "image", "video"],
                        ["clean"],
                      ],
                    }}
                    formats={[
                      "header",
                      "bold",
                      "italic",
                      "underline",
                      "strike",
                      "blockquote",
                      "list",
                      "bullet",
                      "link",
                      "image",
                      "video",
                    ]}
                  />
                  {errors.cancelPolicyText && (
                    <div className="text-danger">{errors.cancelPolicyText}</div>
                  )}
                </div>
  
                {/* Cancellation Policy Rules */}
                <div className="mb-4">
                  <Label style={{ margin:40}}>Cancellation Policy Rules</Label>
                  {cancelPolicyRules.map((rule, index) => (
                    <Row key={index} className="mb-3 align-items-end">
                      <Col md={3}>
                        <Label for={`startDays_${index}`}>Start Days</Label>
                        <Input
                          type="number"
                          name={`startDays_${index}`}
                          id={`startDays_${index}`}
                          value={rule.startDays}
                          onChange={(e) =>
                            handleRuleChange(
                              index,
                              "startDays",
                              e.target.value
                            )
                          }
                          placeholder="e.g., 30"
                          min="0"
                        />
                      </Col>
                      <Col md={3}>
                        <Label for={`endDays_${index}`}>End Days</Label>
                        <Input
                          type="number"
                          name={`endDays_${index}`}
                          id={`endDays_${index}`}
                          value={rule.endDays}
                          onChange={(e) =>
                            handleRuleChange(
                              index,
                              "endDays",
                              e.target.value
                            )
                          }
                          placeholder="e.g., 10"
                          min="0"
                        />
                      </Col>
                      <Col md={3}>
                        <Label for={`type_${index}`}>Type</Label>
                        <Input
                          type="select"
                          name={`type_${index}`}
                          id={`type_${index}`}
                          value={rule.type}
                          onChange={(e) =>
                            handleRuleChange(index, "type", e.target.value)
                          }
                        >
                          <option value="percentage">Percentage</option>
                          <option value="fixed_deduction">Fixed Deduction</option>
                        </Input>
                      </Col>
                      <Col md={2}>
                        <Label for={`amount_${index}`}>Amount</Label>
                        <Input
                          type="number"
                          name={`amount_${index}`}
                          id={`amount_${index}`}
                          value={rule.amount || 0}
                          onChange={(e) =>
                            handleRuleChange(index, "amount", e.target.value)
                          }
                          placeholder="e.g., 50"
                          min="0"
                        />
                      </Col>
                      <Col md={1}>
                        <Button
                          color="danger"
                          onClick={() => removeRule(index)}
                          disabled={cancelPolicyRules.length === 1}
                        >
                          &times;
                        </Button>
                      </Col>
                      {errors[`rule_${index}`] && (
                        <Col md={12}>
                          <div className="text-danger">
                            {errors[`rule_${index}`]}
                          </div>
                        </Col>
                      )}
                    </Row>
                  ))}
                  <Button color="secondary" onClick={addRule}>
                    Add Rule
                  </Button>
                  {errors.cancelPolicyRules && (
                    <div className="text-danger mt-2">
                      {errors.cancelPolicyRules}
                    </div>
                  )}
                </div>
  
                {/* Submit Button */}
                <div className="text-end">
                  <Button color="primary" type="submit">
                    Submit
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  };

const PackageModal = ({ show, closeModal, addData, currentPolicyData }) => {
    console.log("policy-0",currentPolicyData);
  return (
    <Modal
      isOpen={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered={true}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myLargeModalLabel">
          Cancellation Policy Info
        </h5>
        <button
          onClick={closeModal}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <FormComponent addData={addData} currentPolicyData={currentPolicyData} closeModal={closeModal} />
      </div>
    </Modal>
  );
};

export default PackageModal;
